import React, { useEffect, useState, useContext, useMemo, useRef } from 'react';
import classNames from 'classnames';
import { Virtuoso } from 'react-virtuoso';
import { RedactionPanelContext } from './RedactionPanelContext';
import CustomRedactionContext from './CustomRedactionContext'
import './RedactionPanel.scss';
import RedactionPageGroup from '../RedactionPageGroup';
import AnnotationFilterHelpers from '../AnnotationFilterHelpers';
import MultiPanelContext from '../MutliTaskRedactionProvider';
import { getStyleAndDisplayCategory } from '../SearchPanel/RedactionSearchResultsContainer';
import {mapAnnotationToRedactionType, markStyles, redactionTypeMap} from "../../../constants";
import WebViewerContext from "../../../contexts/webviewer-context";
import SelectedMarkStyleContext from "../../../contexts/selected-mark-style-context";
import {BasicAnnotationFilter} from "../BasicAnnotationFilter";
import {Menu, MenuItem} from "@mui/material";

export const RedactionPanel = (props) => {
  const {
    redactionAnnotations,
    applyCheckedRedactions,
    deleteCheckedRedactionAnnotations,
    redactionTypesDictionary,
    selectedTab
  } = props;

  const { setInstance,instance } = useContext(WebViewerContext);
  const { selectedMarkStyle, setSelectedMarkStyle } = useContext(SelectedMarkStyleContext)
  const [anchorEl, setAnchorEl] = useState(null);
  const [redactionPageMap, setRedactionPageMap] = useState({});
  const [redactionPageNumbers, setRedactionPageNumbers] = useState([]);
  const { selectedRedactionItemId, setSelectedRedactionItemId } = useContext(RedactionPanelContext);
  const [pageRangeFilter, setPageRangeFilter] = React.useState(undefined);
  const [searchPhraseFilter, setSearchPhraseFilter] = React.useState(undefined)
  const [categoryFilter, setCategoryFilter] = React.useState(undefined)
  let currentRedactionsRef = useRef([])
  let redactionPageMapRef = useRef({})
  let pressedKeys=useRef([]);
  const redactionTab = 1
  function filterPageRangeOnChange(newPageRange) {
    //We need to clone the value so that React recognizes it as a change and re-renders.
    const verifiedPageRange = newPageRange === undefined ? undefined : [...newPageRange];
    setPageRangeFilter(verifiedPageRange);
  }

  function searchPhraseOnChange(newSearchPhrase) {
    setSearchPhraseFilter(newSearchPhrase)
  }

  function categoryOnChange(newCategory) {
    setCategoryFilter(newCategory)
  }

  const refid = useRef(null);
  const prevPage = useRef(null)
  const scrollIntoViewById = (id) => {
    if (id) {
      const el = document.querySelector('#annotation-' + id);
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }
  useEffect(() => {
    const onKeyUpHandler = (event) => {
      pressedKeys.current=pressedKeys.current.filter((key) => key!==event.key)
    }

    const onKeyDownHandler = (event) => {
      if (selectedTab.current!==redactionTab) {
        return
      }
      if (event.key === 'Shift' || event.key === 'Control' || event.key === 'Meta') {
        pressedKeys.current.push(event.key)
      }
      if (event.key === 'ArrowLeft' || event.key === 'ArrowUp') {
        event.preventDefault();
        if (refid.current.selectedRedactionItemId) {
          for (let page in refid.current.redactionPageMap) {
            const currentPage = refid.current.redactionPageMap[page]
            for (let i = 0; i < currentPage.length; i++) {
              if (currentPage[i].Id === refid.current.selectedRedactionItemId) {
                // check if we've another item after this one
                if (currentPage[i - 1]) {
                  const currentAnnotation = currentPage[i - 1]
                  if (!pressedKeys.current.includes('Control') && !pressedKeys.current.includes('Meta') && !pressedKeys.current.includes('Shift')) {
                    instance.Core.annotationManager.deselectAllAnnotations();
                  }
                  instance.Core.annotationManager.selectAnnotation(currentAnnotation);
                  instance.Core.annotationManager.jumpToAnnotation(currentAnnotation);
                  setSelectedRedactionItemId(currentAnnotation.Id);
                  scrollIntoViewById(currentAnnotation.Id)
                  break;
                } else if (prevPage.current) {
                  if (refid.current.redactionPageMap[prevPage.current]) {
                    const currentAnnotation = refid.current.redactionPageMap[prevPage.current][refid.current.redactionPageMap[prevPage.current].length - 1]
                    if (!pressedKeys.current.includes('Control') && !pressedKeys.current.includes('Meta') && !pressedKeys.current.includes('Shift')) {
                      instance.Core.annotationManager.deselectAllAnnotations();
                    }
                    instance.Core.annotationManager.selectAnnotation(currentAnnotation);
                    instance.Core.annotationManager.jumpToAnnotation(currentAnnotation);
                    setSelectedRedactionItemId(currentAnnotation.Id)
                    scrollIntoViewById(currentAnnotation.Id)
                    break;
                  }
                }
              }
            }
            prevPage.current = page;
          }
        }
      }
      else if (event.key === 'ArrowDown' || event.key === 'ArrowRight') {
        event.preventDefault();
        if (refid.current.selectedRedactionItemId) {
          for (let page in refid.current.redactionPageMap) {
            const currentPage = refid.current.redactionPageMap[page]
            for (let i = 0; i < currentPage.length; i++) {
              if (currentPage[i].Id === refid.current.selectedRedactionItemId) {
                // check if we've another item after this one
                if (currentPage[i + 1]) {
                  const currentAnnotation = currentPage[i + 1]
                  if (!pressedKeys.current.includes('Control') && !pressedKeys.current.includes('Meta') && !pressedKeys.current.includes('Shift')) {
                    instance.Core.annotationManager.deselectAllAnnotations();
                  }
                  instance.Core.annotationManager.selectAnnotation(currentAnnotation);
                  instance.Core.annotationManager.jumpToAnnotation(currentAnnotation);
                  setSelectedRedactionItemId(currentAnnotation.Id)
                  scrollIntoViewById(currentAnnotation.Id)
                  break;
                }
                else {
                  const keys = Object.keys(refid.current.redactionPageMap);
                  const theOne = keys.findIndex(val => val === page);
                  if (theOne !== -1) {
                    const needed = refid.current.redactionPageMap[keys[theOne + 1]];
                    if (needed) {
                      const id = needed[0].Id
                      if (!pressedKeys.current.includes('Control') && !pressedKeys.current.includes('Meta') && !pressedKeys.current.includes('Shift')) {
                        instance.Core.annotationManager.deselectAllAnnotations();
                      }
                      instance.Core.annotationManager.selectAnnotation(needed[0]);
                      instance.Core.annotationManager.jumpToAnnotation(needed[0]);
                      setSelectedRedactionItemId(id)
                      scrollIntoViewById(id)
                      return
                    }
                  }
                }
              }
            }
          }
        }
      } else if ((pressedKeys.current.includes('Meta') || pressedKeys.current.includes('Control'))) {
        if (event.key === 'a') {
          event.preventDefault()
          instance.Core.annotationManager.selectAnnotations(currentRedactionsRef.current)
        } else if (event.key === 'c' ) {
          setCheckedStatusforSelected(true)
        } else if (event.key === 'u' ) {
          event.preventDefault()
          setCheckedStatusforSelected(false)
        }
      }
    }

    const onAnnotationSelected = (annotations, action) => {
      const selectedReds = instance.Core.annotationManager.getSelectedAnnotations().filter((redaction) => redaction.elementName==='redact')
      if (selectedReds.length===0) {
        return;
      }
      refid.current = {selectedRedactionItemId: selectedReds[selectedReds.length - 1].Id, redactionPageMap: redactionPageMapRef.current}
    };

    const onPanelRedactionItemClicked = ({annotation}) => {
      if (!pressedKeys.current.includes('Control') && !pressedKeys.current.includes('Meta') && !pressedKeys.current.includes('Shift')) {
        instance.Core.annotationManager.deselectAllAnnotations();
      }
      instance.Core.annotationManager.selectAnnotation(annotation);
      instance.Core.annotationManager.jumpToAnnotation(annotation);
    };

    // add listener
    window.addEventListener('keydown', onKeyDownHandler)
    window.addEventListener('keyup', onKeyUpHandler)
    window.oncontextmenu = () => { pressedKeys.current=[] } // when the user right click, the onKeyUpHandler doesn't get triggered
    window.onblur = () => { pressedKeys.current=[] }
    instance.Core.annotationManager.addEventListener('annotationSelected', onAnnotationSelected);
    instance.Core.annotationManager.addEventListener('panelRedactionItemSelected', onPanelRedactionItemClicked);
    // prevent memory leak
    return () => {
      window.removeEventListener('keydown', onKeyDownHandler);
      window.removeEventListener('keyup', onKeyUpHandler);
      instance.Core.annotationManager.removeEventListener('panelRedactionItemSelected', onPanelRedactionItemClicked);
      instance.Core.annotationManager.removeEventListener('annotationSelected', onAnnotationSelected);
      window.oncontextmenu=null
      window.onblur=null
    }
  }, [])
  useEffect(() => {
    refid.current = { selectedRedactionItemId, redactionPageMap };
  }, [selectedRedactionItemId, redactionPageMap])

  const multiPaneContext = useContext(MultiPanelContext)

  useEffect(() => {
    //We need to map these fields to the ones that are used in the filter helper methods.
    redactionAnnotations.forEach(annotation => {
      annotation.pageNum = annotation.PageNumber
      //Marks added by selecting text have an annot-preview but contents is undefined.
      //Marks from Adobe have an empty string for the contents.
      annotation.resultStr = annotation.getContents() ? annotation.getContents() : annotation.getCustomData('trn-annot-preview');
    })
    AnnotationFilterHelpers.applyFilters(redactionAnnotations, searchPhraseFilter, pageRangeFilter, categoryFilter)

    const redactionPageMap = {};
    currentRedactionsRef.current=[]
    redactionAnnotations.forEach((annotation) => {
      const redactionType = mapAnnotationToRedactionType(annotation);
      const { label, icon } = redactionTypesDictionary[redactionType];
      annotation.label = label;
      annotation.icon = icon;
      annotation.redactionType = redactionType;

      if (annotation.shouldHide) {
        return
      }

      const pageNumber = annotation.PageNumber;
      if (redactionPageMap[pageNumber] === undefined) {
        redactionPageMap[pageNumber] = [annotation];
      } else {
        redactionPageMap[pageNumber] = [annotation, ...redactionPageMap[pageNumber]];
      }
      currentRedactionsRef.current.push(annotation)
    });
    setRedactionPageMap(redactionPageMap);
    redactionPageMapRef.current = redactionPageMap
    setRedactionPageNumbers(Object.keys(redactionPageMap));
    // try to update the context
    if(multiPaneContext && multiPaneContext.onRedactionChange) {
      multiPaneContext.onRedactionChange(redactionAnnotations);
    }
  }, [redactionAnnotations, pageRangeFilter, searchPhraseFilter, categoryFilter]);

  const renderRedactionPageGroups = () => {
    return (
      <div className="redaction-search-results-container" role="list">
        <CustomRedactionContext.Provider value={(ids, checked) => {
          for (let page in redactionPageMap) {
            const currentPage = redactionPageMap[page]
            for (let i = 0; i < currentPage.length; i++) {
              if (ids.includes(currentPage[i].Id)) {
                const cloned = { ...redactionPageMap };
                cloned[page][i].markChecked = checked;
                setRedactionPageMap(cloned)
                break;
              }
            }
          }
        }}>

          <Virtuoso
            data={redactionPageNumbers}
            itemContent={(index, pageNumber) => {
              return (
                <RedactionPageGroup
                  key={index}
                  pageNumber={pageNumber}
                  redactionItems={redactionPageMap[pageNumber]}
                />);
            }}
          />
        </CustomRedactionContext.Provider>
      </div>
    );
  };

  const noRedactionAnnotations = (
    <div className="no-marked-redactions">
      <div className="msg">No Redactions</div>
    </div>
  );

  const redactAllButtonClassName = classNames('redact-all-marked', { disabled: redactionAnnotations.length === 0 });
  const clearAllButtonClassName = classNames('clear-all-marked', { disabled: redactionAnnotations.length === 0 });
  const canShowCommit = useMemo(() => {
    let res = false;
    for (let page in redactionPageMap) {
      const currentPage = redactionPageMap[page]
      for (let i = 0; i < currentPage.length; i++) {
        if (currentPage[i].markChecked) {
          res = true
          break;
        }
      }
    }
    const doc = document.getElementsByTagName('apryse-webviewer')[0].shadowRoot;
    if (doc.querySelector('[data-element="changeOverlayFontButton"]')!==null && doc.querySelector('[data-element="bulkAdjustMarksButton"]') !==null ) {
      doc.querySelector('[data-element="changeOverlayFontButton"]').disabled = !res;
      doc.querySelector('[data-element="bulkAdjustMarksButton"]').disabled = !res;
      if (res === true) {
        doc.querySelector('[data-element="changeOverlayFontButton"]').classList.remove('disabled');
        doc.querySelector('[data-element="bulkAdjustMarksButton"]').classList.remove('disabled');
      } else {
        doc.querySelector('[data-element="changeOverlayFontButton"]').classList.add('disabled');
        doc.querySelector('[data-element="bulkAdjustMarksButton"]').classList.add('disabled');
      }
    }
    return res
  }, [redactionPageMap])

  const isEmptyList = redactionAnnotations.length === 0;

  const setCheckedStatus = (status) => {
    const cloned = { ...redactionPageMapRef.current };
    for (let page in cloned) {
      const currentPage = cloned[page]
      for (let i = 0; i < currentPage.length; i++) {
        currentPage[i].markChecked = status
      }
    }
    setRedactionPageMap(cloned)
  }

  const setCheckedStatusforSelected = (status) => {
    const cloned = { ...redactionPageMapRef.current };
    const selectedReds = instance.Core.annotationManager.getSelectedAnnotations().filter((redaction) => redaction.elementName==='redact')
    for (let page in cloned) {
      const currentPage = cloned[page]
      for (let i = 0; i < currentPage.length; i++) {
        if (selectedReds.includes(currentPage[i])) {
          currentPage[i].markChecked = status
        }
      }
    }
    setRedactionPageMap(cloned)
  }

  const checkAllItems = () => {
    setCheckedStatus(true)
  };

  const uncheckAllItems = () => {
    setCheckedStatus(false)
  };

  const [totalRedactionsToApply, setTotalRedactionsToApply] = useState(0);

  const handleApplyCheckedRedactions = () => {
    setTotalRedactionsToApply(redactionAnnotations.filter(redaction => redaction.markChecked).length);
    setTimeout(() => {
      applyCheckedRedactions()
    }, 100);
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAdvanceOptionsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };


  const handleClickChangeCategory = () => {
    let checkedAnnotations = [];
    for (let page in redactionPageMap) {
      const currentPage = redactionPageMap[page]
      for (let i = 0; i < currentPage.length; i++) {
        if (currentPage[i].markChecked === true) {
          checkedAnnotations.push(currentPage[i])
        }
      }
    }
    instance.Core.annotationManager.trigger('changeCategory', {annotations: checkedAnnotations});
  }

  const handleClickChangeOutlineColor = () => {
    let annotations = [];
    for (let page in redactionPageMap) {
      const currentPage = redactionPageMap[page]
      for (let i = 0; i < currentPage.length; i++) {
        if (currentPage[i].markChecked === true) {
          annotations.push(currentPage[i])
        }
      }
    }
    instance.Core.annotationManager.trigger('changeOutlineColor', { annotations });
  }

  const handleClickUpdateStyle= () => {
    const cloned = { ...redactionPageMap };
    let updatedRedactions = [];
    const props = selectedMarkStyle.props;
    for (let page in redactionPageMap) {
      const currentPage = redactionPageMap[page]
      for (let i = 0; i < currentPage.length; i++) {
        if (currentPage[i].markChecked === true && currentPage[i].type !== redactionTypeMap['FULL_PAGE']) {
          const [styleCategory, displayCategory] = getStyleAndDisplayCategory(currentPage[i].type)

          cloned[page][i].FillColor['R'] = props[styleCategory]['FillColor']['R'];
          cloned[page][i].FillColor['G'] = props[styleCategory]['FillColor']['G'];
          cloned[page][i].FillColor['B'] = props[styleCategory]['FillColor']['B'];
          cloned[page][i].FillColor['A'] = props[styleCategory]['FillColor']['A'];
          cloned[page][i].OverlayText = props[styleCategory]['OverlayText'];
          cloned[page][i].TextColor['R'] = props[styleCategory]['TextColor']['R'];
          cloned[page][i].TextColor['G'] = props[styleCategory]['TextColor']['G'];
          cloned[page][i].TextColor['B'] = props[styleCategory]['TextColor']['B'];
          cloned[page][i].TextColor['A'] = props[styleCategory]['TextColor']['A'];
          cloned[page][i].StrokeColor['R'] = props[styleCategory]['StrokeColor']['R'];
          cloned[page][i].StrokeColor['G'] = props[styleCategory]['StrokeColor']['G'];
          cloned[page][i].StrokeColor['B'] = props[styleCategory]['StrokeColor']['B'];
          cloned[page][i].StrokeColor['A'] = props[styleCategory]['StrokeColor']['A'];
          cloned[page][i].Font = props['Font'];
          cloned[page][i].FontSize = props['FontSize'];

          updatedRedactions.push(cloned[page][i]);
        }
      }
    }
    setRedactionPageMap(cloned)
    instance.Core.annotationManager.trigger('styleUpdate', { style: selectedMarkStyle.name, redactions: updatedRedactions });
  }

  return (
    <>
      <div>
        {/*TODO Redact Progress Bar*/}
        {/*{isLoadingModalOpen && <RedactProgressBar marksToRedact={totalRedactionsToApply}/> }*/}
        <div className="extra-options" style={{marginTop: 0, marginBottom: 20}}>
          <button className='Button' onClick={handleAdvanceOptionsClick}>Advanced Options
            {Boolean(anchorEl) ? "  ↑" : "  ↓"}</button>
        </div>
        <div className="redaction-panel-controls" style={{backgroundColor: "inherit", border: "none"}}>
          <button
              disabled={!canShowCommit}
              className={clearAllButtonClassName}
              onClick={deleteCheckedRedactionAnnotations}
              aria-label='Clear'
          >
            Delete
          </button>
          <button
              disabled={!canShowCommit}
              className={redactAllButtonClassName}
              onClick={handleApplyCheckedRedactions}
              aria-label='Redact'
          >
            Redact
          </button>
        </div>
        <div>
          <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              className={'transform-menu'}
              style={{zIndex: 99999}}
          >
            <MenuItem disabled={!canShowCommit} onClick={() => {
              handleClose();
              handleClickChangeCategory()
            }}>Change Category</MenuItem>
            <MenuItem disabled={!canShowCommit} onClick={() => {
              handleClose();
              handleClickChangeOutlineColor()
            }}>Change Outline Color</MenuItem>
            <MenuItem disabled={!canShowCommit} onClick={() => {
              handleClose();
              handleClickUpdateStyle()
            }}>Update Style</MenuItem>
          </Menu>
        </div>
      </div>
      <div className="box marked-redaction-counter" style={{marginTop: 12}}>
        <span>Marked for Redaction</span> {`(${AnnotationFilterHelpers.getCounter(redactionAnnotations)})`}
        <button className={"checkButton"}
                onClick={checkAllItems}
                disabled={isEmptyList}
                aria-label={'Select All'}
        >
          {'Select All'}
        </button>
        <button className={"checkButton"}
                disabled={isEmptyList}
                onClick={uncheckAllItems}
                aria-label={'Unselect All'}
        >
          {'Unselect All'}
        </button>
      </div>
      <BasicAnnotationFilter pageRangeChangeCallBack={filterPageRangeOnChange} searchPhraseChangeCallBack={searchPhraseOnChange} categoryChangeCallBack={categoryOnChange} categories={AnnotationFilterHelpers.getCategories(redactionAnnotations)} />
      {redactionPageNumbers.length > 0 ? renderRedactionPageGroups() : noRedactionAnnotations}
      <div className="redaction-panel-controls-bottom">
        <button
          disabled={!canShowCommit}
          className={clearAllButtonClassName}
          onClick={deleteCheckedRedactionAnnotations}
          aria-label='Clear'
        >
          Delete
        </button>
        <button
          disabled={!canShowCommit}
          className={redactAllButtonClassName}
          onClick={handleApplyCheckedRedactions}
          aria-label='Redact'
        >
          Redact
        </button>
      </div>
    </>
  );
};
