import React, { createContext, useRef, useContext } from 'react';

type CancellationToken = {
    cancelled: boolean;
};

type CancellationContextType = React.MutableRefObject<CancellationToken> | null;

const SearchCancellationContext = createContext<CancellationContextType>(null);

export const SearchCancellationProvider: React.FC<{children: React.ReactNode}> = ({ children }) => {
    const cancellationTokenRef = useRef<CancellationToken>({ cancelled: false });

    return (
        <SearchCancellationContext.Provider value={cancellationTokenRef}>
            {children}
        </SearchCancellationContext.Provider>
    );
};

export const useSearchCancellation = () => {
    const cancellationTokenRef = useContext(SearchCancellationContext);

    if (!cancellationTokenRef) {
        throw new Error('useSearchCancellation must be used within a SearchCancellationProvider');
    }

    return cancellationTokenRef;
};