import { useAuthService } from "../../contexts/auth-context";
import { AccountModal } from "../modals/account-modal";
import "../pages.scss";

export function LandingPageUser() {
    const { loginInfo } = useAuthService();
    return <div className="body-container" style={{ flexDirection: "column" }}>
        <div style = {{marginBottom: -32}}>
            <AccountModal>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <p style={{ fontSize: 36, }}>Hello, {loginInfo?.tenant?.user?.name || loginInfo?.user?.name}</p>
                <div style={{ fontSize: 15 }}>
                    <span>
                        Welcome to the Real Life Sciences (RLS) web platform v2024.4.0.
                    </span>
                </div>
            </div>
        </AccountModal>
        </div>
        <AccountModal>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <p style={{ fontSize: 36 }}>Getting started</p>
                <div style={{ fontSize: 15 }}>
                    <span>Click on <b>Project Management</b> from the left menu bar to get started.</span>
                    <br />
                    <span>From there you’ll go through an onboarding process to help you get accustomed to the new feature. </span>
                    <p>
                        If you want to see your account details and make changes to your profile, please access&nbsp;
                        <b>My account</b> from the top right of the screen.
                    </p>
                    <span>
                        If you have any issues, feedback or you need technical assistance please contact us using the&nbsp;
                        <b>Support & Feedback</b> page.
                    </span>
                    <br />
                </div>
            </div>
        </AccountModal>
    </div>
}
