
//A few static helper methods that handle the logic of filtering. It may be possible to move this into AnnotationFilter.js in the future
export default class AnnotationFilterHelpers {
    static pageNumValid(pageNum: number, pageRange: undefined|(undefined|number)[]) {
        if (pageRange === undefined) {
            return true;
        }
        if (pageNum === undefined) {
            console.log("The pageNum of this annotation is undefined. This shouldn't happen.")
            return true;
        }

        return pageNum >= (pageRange[0] ?? Number.NEGATIVE_INFINITY) && pageNum <= (pageRange[1] ?? Number.POSITIVE_INFINITY)
    }

    static textValid(text: string, searchPhrase: string|undefined) {
        if (searchPhrase === undefined) {
            return true;
        }
        if (text === undefined || typeof text !== 'string') {
            console.log("The resultStr of this annotation is undefined. This shouldn't happen.")
            return true;
        }
        //Returns true as long as the text of the annotation contains the search phrase anywhere inside it.
        //This may be too general in some cases but I think it's the best solution.
        return text.toLowerCase().includes(searchPhrase.toLowerCase())
    }

    static categoryValid(type: string, category: string|undefined) {
        if (category === undefined) {
            return true;
        }
        if (type === undefined) {
            console.log("The type of this annotation is undefined. This shouldn't happen.")
            return true;
        }
        return type.toLowerCase() == category.toLowerCase()
    }

    static regexInputValid(text: string, regexInput: string|undefined) {
        if (regexInput===undefined || regexInput==='') {//in case of empty input
            return true
        }
        try {
            const regex = new RegExp(regexInput);
            return regex.test(text);
        } catch (e) {//in case of invalid input
            return false
        }
    }

    //We apply this logic to both search results and annotations. It will work for anything that has the fields
    //pageNum and resultStr.
    //It will add a new field called shouldHide on each annotation, which is true if it needs to be filtered out.
    static applyFilters(annotations: any[], searchPhrase: any, pageRange: any, category: any) {
        if (annotations === undefined) {
            return;
        }

        annotations.forEach((annotation) => {
            if (AnnotationFilterHelpers.pageNumValid(annotation.pageNum, pageRange) &&
                AnnotationFilterHelpers.textValid(annotation.resultStr, searchPhrase) &&
                AnnotationFilterHelpers.categoryValid(this.getCategory(annotation), category)) {
                annotation.shouldHide = false
            } else {
                annotation.shouldHide = true
            }
        });
    }

    static applyFiltersTransform(annotations: any[], searchPhrase: string|undefined, pageRange: undefined|(undefined|number)[], category: string|undefined, regexInput: string|undefined) {
        if (annotations === undefined) {
            return;
        }

        annotations.forEach((annotation) => {
            if (AnnotationFilterHelpers.pageNumValid(annotation.pageNum, pageRange) &&
                (AnnotationFilterHelpers.textValid(this.getOriginalText(annotation), searchPhrase) ||
                    AnnotationFilterHelpers.textValid(annotation.getCustomData("Replacement"), searchPhrase)) &&
                AnnotationFilterHelpers.categoryValid(this.getCategory(annotation), category) &&
                AnnotationFilterHelpers.regexInputValid(this.getOriginalText(annotation), regexInput)) {
                annotation.shouldHide = false
            } else {
                annotation.shouldHide = true
            }
        });
    }

    static getOriginalText(annotation: any) {
        //The original text will be stored in one of these three fields.
        return annotation.getCustomData('trn-annot-preview') || annotation.resultStr || annotation.getContents();
    }

    //Returns a string showing how many of the total annotations are being displayed. For example "100/500".
    //If no filters are being applied it just returns the total number of annotations.
    static getCounter(annotations: any[]) {
        let resultsDisplayed = 0;

        for (const annotation of annotations) {
            if (annotation.shouldHide === false) {
                resultsDisplayed++;
            }
        }
        return resultsDisplayed === annotations.length ? annotations.length : `${resultsDisplayed}/${annotations.length}`
    }

    static getCategory(annotation: any) {
        return annotation.type || annotation.getCustomData?.('trn-redaction-type')
    }

    //Gets an alphabetical list of every category used in the list of annotations
    static getCategories(annotations: any[]) {
        // @ts-ignore
        const uniqueTypes = [...new Set(annotations.map(annotation => this.getCategory(annotation)))];
        const sortedTypes = uniqueTypes.sort();
        return sortedTypes
    }
}
