/* fileManagement.ts */
import axios from "axios";
import * as Sentry from "@sentry/react";
import {
    commitApiFile, commitApiFileWithAcceleration, generatePreSignedURL, initiateUploading,
    postApiFile,
    putFileToS3PreSignedUrl,
    requestUploadUrlForFile, updateFileTagByVersionId,
} from "../services/files"
import {FileState} from "../models/FileState";
import JSZip from "jszip";
import {LargeFileUpload} from "../models/LargeFileUpload";
import {PreSignedS3UploadUrl} from "../models/PreSignedS3UploadUrl";
interface UploadPart {
    ETag: string;
    PartNumber: number;
}
export function validateFile(fileType : string, fileName: string) {
    return (fileType === "application/pdf" || fileType === "text/csv"  || fileType === "application/vnd.ms-excel"  || fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        || fileType === "application/x-sas-data"  || fileType === "application/x-sas-xport" || fileType === "application/arx" ) ||
        (fileType === "" && fileName.endsWith(".deid")) //Files from Risk (.deid) don't get assigned a MIME type in OSX, so we validate them this way.
}

export async function updateFileUsingS3(file: any, projectId: number, fileId: string, temporaryLocalChangesSave: boolean, tag?: string): Promise<FileState | undefined>{
    try {
        if(!validateFile(file.type, file.name)) {
            reportError("error!");
            return;
        }

        const preSignedUrl = await requestUploadUrlForFile() as PreSignedS3UploadUrl
        await putFileToS3PreSignedUrl(preSignedUrl.url, file)

        const fileUpload : LargeFileUpload = {
            preSignedUrl,
            name: decodeURI(file.name),
            category: "",
            projectId: projectId,
            isDocOpen: true,
            size: file.size,
            openedBy: "", //backend overrides this value
            createdByName: "", //backend overrides this value
            isUpdating: true,
            isTemporaryLocalChanges: temporaryLocalChangesSave,
            id: fileId
        };

        await commitApiFile(fileUpload)
        if(tag && !temporaryLocalChangesSave) {
           await updateFileTagByVersionId(projectId.toString(), decodeURI(file.name), "current", tag);
        }

        return undefined
    } catch (exception){
        Sentry.captureException(exception);
        console.log(exception);
        return undefined;
    }
}

export async function uploadFileUsingS3(file: any, projectId: number, fileUploader: number | undefined,
                                        isSavingExistingFile: boolean, initializeDocAsOpen: boolean): Promise<FileState|undefined>{

    try {
        if(!validateFile(file.type, file.name)) {
            reportError("error!");
            return;
        }
        const preSignedUrl = await requestUploadUrlForFile() as PreSignedS3UploadUrl
        await putFileToS3PreSignedUrl(preSignedUrl.url, file)
        const category = file.name.endsWith('deid') ? "RLS Protect Risk" : "Unassigned"
        const fileUpload : LargeFileUpload = {
            preSignedUrl,
            name: file.name,
            category: category,
            projectId: projectId,
            isDocOpen: initializeDocAsOpen,
            size: file.size,
            openedBy: fileUploader?.toString() || "", //backend overrides this value
            createdByName: "", //backend overrides this value
            isUpdating: false,
            id: "-1"
        };
        const response = await commitApiFile(fileUpload);
        return {
            ...response,
            name: file.name
        }
    } catch (exception){
        Sentry.captureException(exception);
        console.log(exception);
        return undefined;
    }
}
export const startUploadingToS3 = async (
     file: any,
     signal: AbortSignal,
     projectId: number,
     fileUploader: number | undefined,
     isSavingExistingFile: boolean,
     initializeDocAsOpen: boolean,
     fieldId?: string,
     saveTag?: string,
     temporaryLocalChangesSave?: boolean
)=>{
    try {
        if(!validateFile(file.type, file.name)) {
            reportError("error!");
            return;
        }
        const response: Record<string, string | number> = await initiateUploading({
            fileName: file.name,
            fileType: file.type,
            projectId,
            temporaryLocalChangesSave: temporaryLocalChangesSave || false
        }, signal);
       return await uploadMultipartFile({file, uploadId: response.uploadId, signal, projectId,fileUploader, isSavingExistingFile, initializeDocAsOpen, fieldId, saveTag,temporaryLocalChangesSave});
    }catch(exception){
        Sentry.captureException(exception);
        console.log(exception);
        return undefined;
    }
}

const uploadMultipartFile = async (data: any) => {
    const {file, uploadId, signal, projectId, temporaryLocalChangesSave} = data;
    if (!file || !uploadId) {
        console.warn("[WARN]: Missing file or uploadId");
        return;
    }
    try {
        const FILE_CHUNK_SIZE = 5 * 1024 * 1024; // 100MB
        const NUM_CHUNKS = Math.ceil(file.size / FILE_CHUNK_SIZE);
        const promises: Promise<UploadPart>[] = Array.from({
            length: NUM_CHUNKS,
        }).map(async (_, index) => {
            const start = index * FILE_CHUNK_SIZE;
            const end = Math.min(start + FILE_CHUNK_SIZE, file.size);
            const blob = file.slice(start, end);
            const data = await generatePreSignedURL({fileName: file.name, partNumber: index + 1, projectId, uploadId, temporaryLocalChangesSave:temporaryLocalChangesSave ?? false},signal );
            const uploadResp = await axios.put(data['presignedUrl'] as string, blob, {
                headers: { "Content-Type": file.type },
            });
            return {
                ETag: uploadResp.headers.etag,
                PartNumber: index + 1,
            };
        });
        const uploadPartsArray = await Promise.all(promises);
        return await completeMultipartUpload({...data, uploadPartsArray});
    } catch (err) {
        console.error("[ERROR]: Error uploading file", err);
    }
};

const completeMultipartUpload = async (data: any) => {
    const {file, uploadId,uploadPartsArray,isSavingExistingFile, projectId, fieldId, saveTag,temporaryLocalChangesSave} = data;
    try {
        const category = file.name.endsWith('deid') ? "RLS Protect Risk" : "Unassigned"
        const fileUpload : any = {
            name: decodeURI(file.name),
            category: isSavingExistingFile ? "" : category,
            projectId: data.projectId,
            isDocOpen: data.initializeDocAsOpen,
            size: file.size,
            openedBy: data?.fileUploader?.toString() || "", //backend overrides this value
            createdByName: "", //backend overrides this value
            isUpdating: isSavingExistingFile,
            id: fieldId || "-1",
            parts: uploadPartsArray,
            uploadId,
            isTemporaryLocalChanges: temporaryLocalChangesSave,
        };
        const response = await commitApiFileWithAcceleration(fileUpload, data.signal);
        if(saveTag && !temporaryLocalChangesSave) {
            await updateFileTagByVersionId(projectId.toString(), decodeURI(file.name), "current", saveTag);
        }
        return {
            ...response,
            name: file.name
        }

    } catch (err) {
        console.error("[ERROR]: Error completing upload", err);
    }
};


//Keep this method here. We get a presigned URL from the backend but we still need to get the file on the front end.
export const getFileNoCache = async (url: string) => {
    const result = await axios.get(
        url,
        {
            // query URL without using browser cache
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
            },
        }
    )
    return result.data;
}

//The above method does not work for Excel files. This one works for both XLSX and CSV
export const getExcelFileNoCache = async (url: string) => {
    const result = await axios.get(
        url,
        {
            // query URL without using browser cache
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
                'Content-Type': 'blob'
            },
            responseType: 'arraybuffer'
        }
    )
    return result.data;
}

export const getFileNoCacheAndUnzipInMemory = async (url: string, signal: AbortSignal): Promise<Map<string, Promise<string>>> => {
    try {
        const response = await axios.get(url, {
            // Query URL without using browser cache
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
            },
            responseType: 'arraybuffer', // To handle binary data
            signal: signal
        });
        if (response.status === 200) {
            const zip = new JSZip();
            await zip.loadAsync(response.data);
            let extractedFiles = new Map;
            zip.forEach((relativePath, file) => {
                extractedFiles.set(relativePath, file.async('text'));
            });
            return extractedFiles;
        } else {
            throw new Error('Failed to download file');
        }
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};
