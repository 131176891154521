import React, { useRef, useEffect } from 'react';
import WebViewer from '@pdftron/webviewer';
import {Buttons, CompareModalBody, CustomModal} from "./utils/webviewer-modal-button-utils";
import {comparePanelSvg} from "./utils/svg-files";
import WebViewerCompareView from "./utils/webviewer-compare-view";
import {getApiFilesByProjectId} from "../services/files";
import {getFileNameFromURL} from "../pages/user/projects/single-project";
import {Button} from "@mui/material";
import {useAppSelector} from "../hooks/redux-hook";
import {useNavigate} from "react-router-dom";

const CompareViewer = (p: { files: any[], patternSetID?: number, projectID?: number, taskId?: number, initialTaskStatus?: string, isRestoring? : boolean, tag?: string}) => {
    const viewer = useRef(null);
    const webviewerCompareViewRef =useRef(new WebViewerCompareView());
    const { loaded, projects } = useAppSelector(state => state.projects)
    const activeProject = projects.find((val) => val.id === p.projectID);
    const nav = useNavigate();
    let fileNameAndIdMap = new Map<string, string>();
    const loadFiles = async ()=> {
        const files = await getApiFilesByProjectId(p.projectID!)
        const selectedFiles = []
        for (let i = 0; i < p.files.length; i++){
            const index = files.findIndex((file: any)=> getFileNameFromURL(file.url) === getFileNameFromURL(p.files[i]));
            if(index > -1) {
                // @ts-ignore
                fileNameAndIdMap.set(getFileNameFromURL(files[index].url) as string, files[index].id)
                if (p.isRestoring) {
                    selectedFiles.push(p.files[i]);
                } else {
                    selectedFiles.push(files[index].url) //use the URL from the most recent API call, because these AWS URLs expire after 15 minutes.
                }
            }
        }

        return selectedFiles;
    }

    // if using a class, equivalent of componentDidMount
    useEffect(() => {
        const openedFiles = async () => {
            return await loadFiles()
        }
        openedFiles().then(selectedFiles=> {
            webviewerCompareViewRef?.current?.setFiles(selectedFiles)
            WebViewer.WebComponent(
                {
                    licenseKey: process.env.REACT_APP_PDFTRON_LICENSE,
                    path: '/webviewer/lib',
                    fullAPI: true,
                    isAdminUser: true,
                    enableRedaction: false,
                    //ui: 'legacy',
                    //annotationUser: loginInfo?.tenant?.user?.name,
                    //enableViewStateAnnotations: true,
                    //enableAnnotations: false,
                    disabledElements: [
                        'redactionPanel',
                        'fullScreenButton',
                        'toolbarGroup-Shapes',
                        'toolbarGroup-Insert',
                        'toolbarGroup-Forms',
                        'toolbarGroup-FillAndSign',
                        'toolbarGroup-Annotate',
                        'toolbarGroup-Edit',
                    ]
                },
                viewer.current!,
            ).then((instance) => {
                webviewerCompareViewRef?.current?.setInstance(instance);
                const domDoc = document.getElementsByTagName('apryse-webviewer')[0].shadowRoot
                //compare custom modal and button
                const compareProcessingModal = new CustomModal("process-modal", true)
                    .addHeader({title: 'Compare Status', className: "text-center"})
                    .addBody(CompareModalBody.displayFiles(), {}).addFooter(instance)
                // @ts-ignore
                instance.UI.addCustomModal(compareProcessingModal)
                const comparePanelToggleButton = new Buttons("", "compare-toggle-button", () => {
                    if (instance.UI.isElementOpen('comparePanel')) {
                        instance.UI.closeElements(['comparePanel'])
                        comparePanelToggleButton.makeInActive(domDoc)
                    } else {
                        instance.UI.openElements(['comparePanel'])
                        comparePanelToggleButton.makeActive(domDoc)
                    }
                }, comparePanelSvg)


                const processFilesCompare = () => {
                    instance.UI.openElements(['process-modal'])
                    instance.UI.addEventListener(instance.UI.Events.MULTI_VIEWER_READY, () => WebViewerCompareView.processFiles(comparePanelToggleButton));
                    instance.UI.enterMultiViewerMode()
                }

                processFilesCompare()


                instance.UI.setHeaderItems(header => {
                    //header.push(comparePanelToggleButton)
                });
            });
        })
    }, []);

    return (
        <div className="body-container" style={{flexDirection: "column", width: '100%'}}>
            <div style={{display: 'flex', margin: '0 0 0 0', gap: '2.3rem', height: '32px'}}>
                <Button style={{textDecoration: 'underline', color: "blue"}}
                        onClick={() => nav(`/app/user/workflow/projects/${activeProject?.id}`)}>Back
                    to {activeProject?.name}</Button>
            </div>
            <div className="DocViewer">
                <div className="webviewer" ref={viewer} style={{ height: "calc(100vh - 154px)", width: '70%', display: 'inline-block' }}></div>
            </div>
        </div>
    );
};

export default CompareViewer;
