import React, {useState, useEffect, useContext} from 'react';
import WebViewerContext from "../../../contexts/webviewer-context";

const RedactionPanelContext = React.createContext({selectedRedactionItemId: '', setSelectedRedactionItemId: () => {}, setIsRedactionSearchActive: () => {},
  allSelectedRedactionItemIds: []});

const RedactionPanelProvider = ({ children }) => {
  const { setInstance,instance } = useContext(WebViewerContext);
  const [selectedRedactionItemId, setSelectedRedactionItemId] = useState(null);
  const [allSelectedRedactionItemIds, setAllSelectedRedactionItemIds] = useState([]);
  const [isRedactionSearchActive, setIsRedactionSearchActive] = useState(false);

  useEffect(() => {
    const onAnnotationSelected = (annotations, action) => {
      if (action === 'selected') {
        const redactionAnnotations = annotations.filter((annotation) => annotation.Subject === 'Redact');
        // If multiple ones selected, we only use the first one
        const selectedAnnotationId = redactionAnnotations.length > 0 ? redactionAnnotations[0].Id : null;
        setSelectedRedactionItemId(selectedAnnotationId);
        setAllSelectedRedactionItemIds(instance.Core.annotationManager.getSelectedAnnotations().filter((redaction) => redaction.elementName==='redact').map((redaction) => redaction.Id))
      } else {
        setSelectedRedactionItemId(null);
        setAllSelectedRedactionItemIds([])
      }
    };

    instance.Core.annotationManager.addEventListener('annotationSelected', onAnnotationSelected);

    return () => {
      instance.Core.annotationManager.removeEventListener('annotationSelected', onAnnotationSelected);
    };
  }, []);

  const value = {
    selectedRedactionItemId,
    setSelectedRedactionItemId,
    isRedactionSearchActive,
    setIsRedactionSearchActive,
    allSelectedRedactionItemIds
  };

  return <RedactionPanelContext.Provider value={value}>{children}</RedactionPanelContext.Provider>;
};

export { RedactionPanelProvider, RedactionPanelContext };
