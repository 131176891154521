import { WebViewerInstance} from "@pdftron/webviewer";
import {Buttons} from "./webviewer-modal-button-utils";


class WebViewerCompareView {
    static domDoc:ShadowRoot | null = null
    static webviewerInstance: WebViewerInstance | null = null;
    static instance: null | WebViewerCompareView = null
    static files:Array<string>;
    static button: Buttons | null = null;
    constructor() {
        if(WebViewerCompareView.instance){
            return WebViewerCompareView.instance;
        }
        WebViewerCompareView.instance = this;
    }

    setFiles(files: Array<string>) {
        WebViewerCompareView.files =  files
    }
    setInstance(instance: WebViewerInstance) {
        WebViewerCompareView.webviewerInstance = instance
    }

    static processFiles(button: Buttons) {
        this.button = button;
        this.domDoc = document.getElementsByTagName('apryse-webviewer')![0].shadowRoot!;
        const [documentViewer1, documentViewer2] = WebViewerCompareView.webviewerInstance!.Core.getDocumentViewers();
        let documentsLoaded = 0;
        const compareOnceBothDocumentsLoaded = async () => {
            documentsLoaded++;
            if (documentsLoaded === 2) {
                const cb = WebViewerCompareView.compareDocuments();
                await cb(documentViewer1, documentViewer2);
                documentViewer1.removeEventListener('documentLoaded', onDocumentLoaded);
                documentViewer2.removeEventListener('documentLoaded', onDocumentLoaded);
            }
        };
        const onDocumentLoaded = async () => {
            await compareOnceBothDocumentsLoaded();
        };
        documentViewer1.addEventListener('documentLoaded', onDocumentLoaded);
        documentViewer2.addEventListener('documentLoaded', onDocumentLoaded);
        documentViewer1.loadDocument(this.files[0]);
        documentViewer2.loadDocument(this.files[1]);
    }

    static compareDocuments() {
        return async (documentViewer1: any, documentViewer2: any) => {
            //document.querySelector(".multipanel")!.classList.add("close")
            const {Annotations} = WebViewerCompareView.webviewerInstance!.Core
            const shouldCompare = documentViewer1.getDocument() && documentViewer2.getDocument();
            if (shouldCompare) {
                const beforeColor = new Annotations.Color(21, 205, 131, 0.4);
                const afterColor = new Annotations.Color(255, 73, 73, 0.4);
                const options = {beforeColor, afterColor};
                await documentViewer1.startSemanticDiff(documentViewer2, options);
                WebViewerCompareView.webviewerInstance?.UI.closeElements(["process-modal"])
                //document.querySelector(".multipanel")!.classList.add("close")
                WebViewerCompareView.webviewerInstance?.UI.enableFeatures([WebViewerCompareView.webviewerInstance?.UI.Feature.ComparePages]);
                if(!WebViewerCompareView.webviewerInstance?.UI.isElementOpen(('comparePanel'))){
                    WebViewerCompareView?.webviewerInstance?.UI.openElements(['comparePanel']);
                    this.button?.makeActive(this.domDoc)
                }
            }
        }
    }

    static enableComparePages(){
        this.openComparePanel()
    }
    static openComparePanel(){
        this.domDoc?.querySelector<HTMLElement>('[data-element="comparePanelToggleButton"]')?.click();
    }
    static makeTheButtonInactive(){
        this.button?.makeInActive(this.domDoc)
    }

}

export default WebViewerCompareView;